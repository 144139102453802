import lozad from 'lozad'

const els = [...document.querySelectorAll('img')]

els.forEach((el) => {
  let observer = lozad(el, {
    loaded: function(el) {
      el.classList.add('loaded')
    }
  })
  observer.observe()
})
