let init = () => {
  const contactForm = document.getElementById('contact')
  
  preventClick(contactForm)
  
}

let preventClick = (form) => {
  let submitButton = form.querySelector('button[type="submit"]')

  form.onsubmit = () => {
    submitButton.disabled = true
    submitButton.textContent = 'Sending...'
  }
} 

document.addEventListener('DOMContentLoaded', () => {
  init()
})