require("./lazy.js")
require("./parallax.js")
require("./header.js")
require("./tagline.js")
// require("./blog.js")
require("./slider.js")
require("./search.js")
require("./handleForm")
// require("./glide.js")
// require("./reviews.js")
