let showSearchButton = document.getElementById('showSearch')
let hideSearchButton = document.getElementById('hideSearch')
let searchHeader = document.getElementById('search')

function showSearch (show, hide, section) {
  show.classList.remove('lg:inline-block')
  hide.classList.remove('hidden')
  section.classList.add('show')
}

function hideSearch (show, hide, section) {
  show.classList.add('lg:inline-block')
  hide.classList.add('hidden')
  section.classList.remove('show')
}

// Event Listeners
showSearchButton.addEventListener('click', () => {
  showSearch( showSearchButton, hideSearchButton, searchHeader )
})

hideSearchButton.addEventListener('click', () => {
  hideSearch( showSearchButton, hideSearchButton, searchHeader )
})