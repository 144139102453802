let header = document.getElementById('cherryHeader')
let main = document.getElementById('cherryMain')
let y = window.scrollY

let mobileMenu = document.getElementById('mobileHeader')
let mobileOpen = document.getElementById('showMobile')
let mobileClose = document.getElementById('hideMobile')

// Functions
// ---------------------

// Adjust Page
function adjustPage (header, container) {
  let headerHeight = header.offsetHeight

  // Set container padding
  container.style.paddingTop = headerHeight + 'px'
}

// Handle Scrolling
function squeezeHeader (y, header) {
  if (y > 200) {
    header.classList.add('squeeze')
  } else {
    header.classList.remove('squeeze')
  }
}


// Event Listeners
// ----------------------

document.onreadystatechange = () => {
  adjustPage(header, main)
  squeezeHeader(y, header)
}

document.body.onscroll = () => {
  let y = window.scrollY
  squeezeHeader(y, header)
}

window.addEventListener('resize', () => {
  adjustPage(header, main)
})

// Open Mobile menu
mobileOpen.addEventListener('click', () => {
  mobileMenu.classList.add('show')
})

// Close Mobile menu
mobileClose.addEventListener('click', () => {
  mobileMenu.classList.remove('show')
})