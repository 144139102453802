// Variables
let tagArray = document.querySelectorAll('.tagline .tag-items .tag-item')


// Functions
// --------------------

// Show a tagline
function showTags (current, previous) {
  if(current && previous) {
    current.classList.add('active')
    previous.classList.remove('active')
  }
}

// Loop through list of taglines
function loopTags (taglines, time) {
  let tagArraySize = taglines.length
  let arrayEnd = tagArraySize - 1
  let count = 0
  let prev

  setInterval(() => {

    if (count == arrayEnd ) {
      prev = arrayEnd
      count = 0
    } else {
      count ++
      prev = count - 1
    }
    
    let prevTag = taglines[prev]
    let thisTag = taglines[count]

    showTags(thisTag, prevTag)
  }, time)
}

// Show initial tag
function initTag (taglines) {
  let firstTag = taglines[0]
  if (firstTag) {
    firstTag.classList.add('active')
  }
}

// Event Listeners and calling functions

if (tagArray) {
  initTag(tagArray)
  loopTags(tagArray, 3000)
}